/**
 * Global plugins
 * MUST be imported as ESM modules before other dependencies.
 */

import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;

import lodash from 'lodash';
window._  = lodash;

import moment from 'moment';
import 'moment-timezone/builds/moment-timezone-with-data-1970-2030';
window.moment = moment;
